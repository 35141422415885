import React,  {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { saveCurrentView } from "../../../features/currentView/currentViewSlice";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useUpdateHeadlineDetailMutation, useGetHeadlineAthenaGroupsQuery } from "../../../app/api";
import { useGetHeadlineDetailsByHeadlineQuery, useGetSystemGaCodesQuery } from "../../../app/api";
import { selectCurrentHeadlineDateRange } from "../../../features/audiences/audienceSlice";
import { selectWidgetFilters } from "../../../features/currentView/currentViewSlice";
import { createGAQuery } from "../../../data/WidgetData";
import Widget from "../../Widgets/Widget"; 
import PropTypes from "prop-types";
import CompleteArticleGAWidgetCSVDownload from "../../Widgets/CompleteArticleGAWidgetCSVDownload";
import Loading from "../../UIElements/Loading";

export default function HeadlineArticle({passedHeadline=null, selectedAudience=''}){

  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const headlineString = passedHeadline || queryParameters.get("headline"); 
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  const filter = useSelector(selectWidgetFilters);
  const [filterQuery, setFilterQuery] = useState('');
  const [headlineList, setHeadlineList] = useState([]);

  const {data: initHeadlineList, isLoading: headlineLoading, isFetching: headlineFetching } =  useGetHeadlineAthenaGroupsQuery({type: 'headline-group-audiences', headline: headlineString, headline_search: 'all', include: 'all', exclude:'none', audience_code: 'all', filter_query: filterQuery }, {skip: filterQuery == ''});  // details from athena - many rows

  const {data: headlineDetails, refetch: refetchHeadlines} = useGetHeadlineDetailsByHeadlineQuery({headline: headlineString}); // local headline details - one row
  const {data: systemGaCodes} = useGetSystemGaCodesQuery();

  const {data: allAudienceCounts, isFetching: allFetching } =  useGetHeadlineAthenaGroupsQuery({type: 'audience-only', headline: 'all', headline_search: 'all', include: 'all', exclude:'none', audience_code: 'all', filter_query: filterQuery }, {skip: filterQuery == ''});  // details from athena - many rows

  const headline = headlineDetails ? headlineDetails[0] : {};
  const [runCSVDownloader, setRunCSVDownloader] = useState(false);

  const [updateHeadlineDetail] = useUpdateHeadlineDetailMutation();

  const [metadata, setMetadata] = useState({});
  const navigate = useNavigate();

  useEffect (() => {
    if (headlineString) {
      refetchHeadlines();
    }
  }, [headlineString])

  useEffect( () => {
    setFilterQuery(createGAQuery(filter, currentHeadlineDateRange)); // this filter is for audience bars, not widgets
  }, [filter])

  useEffect( () => {
      if (systemGaCodes && initHeadlineList) {
        const map = new Map();
        systemGaCodes?.forEach(item => map.set(item.ga_code, item));
        initHeadlineList?.forEach(item => map.set(item.audience_code, {...map.get(item.audience_code), ...item}));
        const mergedArr = Array.from(map.values());
        setHeadlineList(mergedArr.filter(t => t.ga_code)); // only save audiences we have in system   
      }
  }, [systemGaCodes, initHeadlineList]);


  const getMetaData = async() => {
    const api_key = '154f1472-30f4-4b53-9b0e-783a2e048103';
    const extract_url_base = 'https://opengraph.io/api/1.1/extract/';
    const headline_url = headline?.url?.replaceAll(':','%3A').replaceAll('/','%2F'); 
    const string_params = '?accept_lang=auto&html_elements=h1,h2,h3,h4,h5,p,title&app_id=';
    const url = extract_url_base + headline_url + string_params + api_key;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setMetadata(data);

      //console.log('getMetaData Success', data);
    } catch (error) {
      console.error('getMetatData Error', error);
    }
  }

  useEffect(() => {
    if (headline?.headline && !headlineLoading && !headline.article) {
      getMetaData();
    }
  }, [headlineLoading]);

  useEffect(() => {
    if (metadata?.concatenatedText && headline.headline) {
      updateHeadlineDetail({headline: headline.headline, article: metadata.concatenatedText});
    }
  }, [metadata]);

  const calcAudiences = (audience_category) => {
    let calcList = [];
    headlineList
    .filter(t => t.audience_code)
    .filter(t => t.audience_category == audience_category)
    .map((t) => {
      const allRecord = allAudienceCounts.find(r => r.audience_code == t.audience_code);
      calcList.push({audience_code: t.audience_code, this_count: t.all_count, total_count: allRecord.audience_count})
    })
    return calcList;
  }

  const audienceChange = (t) => {
    if (passedHeadline) {
      console.log('in passedHeadline');
      dispatch(saveCurrentView({type:'currentAudienceUUID', value:t.uuid}));
      dispatch(saveCurrentView({type:'audienceTab', value:{type:'headlines', uuid:'default'}}));
    }
    else {
      console.log('NOT in passedHeadline');
      navigate({pathname: '/dashboard/article/audience', search: createSearchParams({aud: t.audience_code}).toString()})
    }
  }

  //const maxViewers = Math.max.apply(Math, headlineList?.map((o) => {return o.all_count}) );
  const audiences = (audience_category) => {
    const audienceDesc = [...headlineList];

    /*  ** percentage calc is audience count that reads the article vs. total users in that audience category
    */
    // each category should add up to 100%
   
    // Cluster is MULTI-ISSUE audience_category

    const totalThatReadArticle = calcAudiences(audience_category).reduce((acc, curr) => acc + parseInt(curr.this_count),0 );
    return audienceDesc.filter(t => t.audience_code).sort((a,b) => parseFloat((b.all_count*100)/totalThatReadArticle) > parseFloat((a.all_count*100)/totalThatReadArticle) ? 1 : -1) 
      .filter(t => t.audience_category == audience_category)
      .map((t,i) => (
        <div className='audience-bars' key={i}>
          <p className='hover-point' onClick={()=>audienceChange(t)}>{t.title}</p>
          <div className='audience-bar'>
            <div className='bar-weight' style={{width: ((t.all_count * 100)/totalThatReadArticle)+'%' }}></div>
            <p className='percent'>{((t.all_count * 100) / totalThatReadArticle).toFixed(2)}%</p>
          </div>
        </div>
      )
    )
  }


  const articleText = () => {
    if (headline?.article && headline?.article != '') {
        return headline.article.split('If you purchase a product or register for an account through a link on our site')[0] ;
    }
    else {
        return metadata?.concatenatedText ? metadata?.concatenatedText?.split('If you purchase a product or register for an account through a link on our site')[0] : 'Loading...';
    }
  }

  // publish date removed for now
  /*const date = () => {

    const d = Date.parse(headline?.publish_date);
    const dT = new Date(d).toLocaleDateString('en-US');
    return dT;
    
  }*/

  const audiencePercents =
    <>
      <div className='title-divider'>
        <hr/>
        <p>Audiences</p>
        <hr/>
      </div>
 
      <div className='audience-percent-list-wrapper'>
        <div className='audience-percent-list' >
          <h3>Issue-Oriented</h3>
          {headlineFetching || allFetching ? <p>Loading...</p> : headlineList && allAudienceCounts && audiences('ISSUE')}
        </div>
        <div className='audience-percent-list' >
          <h3>Behavioral</h3>
          {headlineFetching || allFetching ? <p>Loading...</p> : headlineList && allAudienceCounts && audiences('BEHAVIORAL')}
        </div>
        <div className='audience-percent-list' >
          <h3>Multi-Issue Pyschographic</h3>
          {headlineFetching || allFetching ? <p>Loading...</p> : headlineList && allAudienceCounts && audiences('MULTI-ISSUE')}
        </div>
      </div>
    </>

  const csvDownloader = () => {
    if (runCSVDownloader) {
      return (
        <div className='allWidgetCSVDownloader article_downloader'>
          <Loading/>
          <CompleteArticleGAWidgetCSVDownload selectedAudience={selectedAudience} headlineFilter={headlineString} setRunCSVDownloader={setRunCSVDownloader}></CompleteArticleGAWidgetCSVDownload>
        </div>
      )
    }
    else {
      return (
        <div className='allWidgetCSVDownloader article_downloader'>
          <button onClick={() => (setRunCSVDownloader(true))}>
            <i className='fa-light fa-arrow-down-to-bracket'></i>
            <p>Download</p>
          </button>   
        </div>
      )
    }
  }

  return (
    <div className='client_dashboard_view_content no_menus'>
      <div className="new-headlines">
        <div className='headline-article-title'>
          <div className="headlines-title-wrapper">
            <div className='headlines'>
              <h1 className=''>{headline?.title}</h1>
              <a  href={headline?.url} target="_blank" rel="noreferrer" className='menu_right_action_wrapper'>
                <i className="fa solid fa-newspaper"></i>
              </a>
            </div>
            <div className="headline-article-audience-display">
              <i className='fa fa-line-chart'/>
              <p>{headlineList?.length} Audiences</p>
            </div>
            <div className='headline-title-article-body'>
              <img src={headline?.img}/>
              <p>{headline && articleText()} </p>
            </div>
          </div>
        </div>
        <div className='headline-article-analytics'>
          {csvDownloader()}
          <div className='headline-article-analytics-wrapper'>
            <div className='headline-article-analytics-widgets-wrapper'>
              <div className='widget-box-wrapper other-widgets'>
                <div className='widget-box'>
                  <Widget widgetType={'bar'} passedType={'age_range_standard'} selectedAudience={selectedAudience} ga={true} headlineFilter={headlineString}/>
                </div>
                <div className='widget-box'>
                  <Widget widgetType={'bar'} passedType={'gender'} selectedAudience={selectedAudience} ga={true} headlineFilter={headlineString}/>
                </div>
                <div className='widget-box'>
                  <Widget widgetType={'bar'} passedType={'ethnicity'} selectedAudience={selectedAudience} ga={true} headlineFilter={headlineString} />
                </div>
                <div className='widget-box'>
                  <Widget widgetType={'bar'} passedType={'likely_voter'} selectedAudience={selectedAudience} ga={true} headlineFilter={headlineString} />
                </div>
                <div className='widget-box'>
                  <Widget widgetType={'bar'} passedType={'party'} selectedAudience={selectedAudience} ga={true} headlineFilter={headlineString} />
                </div>
                <div className='widget-box'>
                  <Widget widgetType={'bar'} passedType={'source'} selectedAudience={selectedAudience} ga={true} headlineFilter={headlineString} />
                </div>
              </div>
              {audiencePercents}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HeadlineArticle.propTypes = {
  passedHeadline: PropTypes.string,
  selectedAudience: PropTypes.string,
}