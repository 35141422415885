import React from "react";
import { stateMappings } from "../../data/WidgetData";
import { useAddGeoEntryMutation, useGetSelectCountiesQuery , useGeosQuery} from "../../app/api";

export default function DevTools (){

  const {data: counties} = useGetSelectCountiesQuery();
  const {data: geos} = useGeosQuery();
  const [addGeo] = useAddGeoEntryMutation();

  const runGetStates = () => { 
    stateMappings.map(t =>{
      getStates(t.stateLong, t.stateShort);
      }
    )
  }
  const runGetCounties = () => {
    counties.map(t => {
      getCounty(t.county, t.state);
    })
  } 

  const getStates = async(state, stateShort) => {
    const result = await fetch('https://api.maptiler.com/geocoding/' + state.replace(' ','+') + ',+United+States.json?key=V7UO2WDWt6ZYIgf34f8C');
    const  jsonResult = await result.json();
    jsonResult.features.map( r => {
      if (r.place_type[0] == 'region') {
        console.log(state, r.center);
        addGeo({type: 'state', name:state, lng: r.center[0], lat: r.center[1], zoom: 6, country: 'US', shortName: stateShort });
      }
    })
  };

  const getCounty = async(county, stateShort) => {
    const checkGeos = geos.find(t => (t.stateShort == stateShort, t.type == 'county', t.name == county));
    if (checkGeos) {
      console.log('county ' + county + 'in ' + stateShort + ' already found!');
    }
    else {
      const thisState = stateMappings.find(t => t.stateShort == stateShort)?.stateLong;
      const result = await fetch('https://api.maptiler.com/geocoding/' + county.replace(' ','+') + '+county,' + thisState.replace(' ','+') + ',United+States.json?key=V7UO2WDWt6ZYIgf34f8C');
      const  jsonResult = await result.json();
      jsonResult.features.map( r => {
        if (r.place_type[0] == 'county') {
          console.log(county, r.center, thisState, r.place_name);
          if (r.place_name.toLowerCase().includes(county.toLowerCase()) && r.place_name.toLowerCase().includes(thisState.toLowerCase())) {
            console.log('adding...');
            addGeo({type: 'county', name:county, lng: r.center[0], lat: r.center[1], zoom: 6, country: 'US', shortName: stateShort });
          }
          else {
            console.log('no match!');
          }
        }
      })
    }
  };

  return (
    <div>
      <hr/>
      <br/>
      <button onClick={() => runGetStates()}>Run update states</button>
      <p>{geos?.filter(t => t.type == 'state')?.length} states in system</p>
      <hr/>
      <br/>
      <button onClick={() => runGetCounties()}>Run update counties</button>
      <p>{geos?.filter(t => t.type == 'county')?.length} counties in system. Due to resource restrictions you may need to run this multiple times</p>
      <hr/>
    </div>
  )
}