import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { topicMappings } from "../../data/HeadlineData";
import { saveCurrentView } from "../../features/currentView/currentViewSlice";
import { useDispatch } from "react-redux";
import { selectInTopics, selectOutTopics } from "../../features/currentView/currentViewSlice";
import { isPermissionsRO } from "../../features/auth/authSlice";

export default function TopicFilter() {

  const dispatch = useDispatch();
  const hasROpermissions = useSelector(isPermissionsRO);
  // Request to allow filter for headline readership page - disabling RO for now
  console.log(hasROpermissions);
  const disableRO = false;

  const uniqueTopics = [...new Set(topicMappings.map(item => item.category))];

  const savedInTopics = useSelector(selectInTopics);
  const [inTopics, setInTopics] = useState(savedInTopics);

  const savedOutTopics = useSelector(selectOutTopics);
  const [outTopics, setOutTopics] = useState([savedOutTopics]);

  useEffect(() => {
    setInTopics(savedInTopics);
  },[savedInTopics]);

  useEffect(() => {
    setOutTopics(savedOutTopics);
  },[savedOutTopics]);

  const adjustInTopics = (topic) => {
    if (!disableRO) {
      let newArray = [...inTopics];
      let index = newArray.indexOf(topic);
      if(index !== -1) { // exists
        newArray.splice(index,1);
        dispatch(saveCurrentView({type:'inTopics', value: newArray}));
      }
      else { // add
        newArray = [...inTopics, topic];
        dispatch(saveCurrentView({type:'inTopics', value: newArray}));
      }
    }
  }

  const adjustOutTopics = (topic) => {
    if (!disableRO) {
      let newArray = [...outTopics];
      let index = newArray.indexOf(topic);
      if(index !== -1) { // exists
        newArray.splice(index,1);
        dispatch(saveCurrentView({type:'outTopics', value: newArray}));
      }
      else { // add
        newArray = [...outTopics, topic];
        dispatch(saveCurrentView({type:'outTopics', value: newArray}));
      }
    }
  }

  const inThisList = (topic, list) => {
    let index = list.indexOf(topic);
    if(index !== -1) { // exists
      return true;
    }
    else{
      return false;
    }
  }

  return (
    <div className=''>
      <h3 className=''> Explore by Topics </h3>
        <table>
          <thead>
            <tr>
              <th className='leftside'>Include?</th>
              <th>Y</th>
              <th>N</th>
            </tr>
          </thead>
          <tbody>
            {uniqueTopics.sort((a,b) => a.localeCompare(b)).map((t,i) => (
              <tr key={i}>
                <td className='leftside'><p>{t}</p></td>
                <td onClick={()=>{inThisList(t, outTopics) ? console.log('disabled') : adjustInTopics(t)}}>
                  {inThisList(t, inTopics) ?
                    <i className='fa-solid fa-square-check'></i>
                    :
                    <i className={inThisList(t, outTopics) ? 'fa-light fa-square unselectable' : 'fa-light fa-square'} ></i>
                  }
                </td>
                <td onClick={()=>{inThisList(t, inTopics) ? console.log('disabled') : adjustOutTopics(t)}}>
                  {inThisList(t, outTopics) ?
                    <i className='fa-solid fa-square-xmark red'></i>
                    :
                    <i className={inThisList(t, inTopics) ? 'fa-light fa-square unselectable' : 'fa-light fa-square'} ></i>
                  }
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
  )
}
