import React from "react";

export default function Methodology() {

  return (
    <>
      <div className='client_dashboard_view_header standard'>
        <h3>Methodology</h3>
    </div>

    <div className='client_dashboard_view_content'>
      <h3>Single Issue Methodology</h3>
      <h2>Single Issue Audiences gauge whether a voter is likely to support a specific issue or agree with a particular sentiment (increased gun control policies, for example). The models are constructed by linking demographic and behavioral data with survey responses and training a predictive algorithm on the resulting dataset. The model assigns a score from 0 to 100 to every voter in the electorate, and a score cutoff value is used to determine that voter’s membership in the audience.</h2>
      <h2>We start by deploying custom surveys on Advance Local websites - either across all topics or targeted to relevant content - or among members of our proprietary research panel for approximately a week (or until we can gather enough responses). The responses are collected and matched in a privacy compliant manner with over 750 attributes on the voter file. Predictive models are built comparing respondents who match the Q&A pairing with respondents who do not match the Q&A pairing.</h2>
      <h2>The modeling methodology varies depending which method generates the best results. We also apply appropriate cutoffs to generate high lift when the model is tested in the holdout population. The model is then applied to the entirety of the voter file, so every single voter has a score. Voters with the appropriate scores for each model are then grouped and accessible as a custom, issue-based audience.</h2>
      <h3>Multi-Issue/ Personas Methodology</h3>
      <h2>Multi-Issue Psychographic Audience models (or Personas) categorize voters into cohorts based on their opinions on various political, economic, and social issues. We begin with the wealth of information about individual voters available on the L2 voter database. The models utilize a clustering technique that can take into consideration dozens of voter attitudes and motivations. We train the model on a sample of 2 million voters (about 1% of the entire voting population) and once we reach a viable clustering technique, we apply the model to the full voter file (approximately 213 million records). Each voter is categorized into one mutually exclusive cohort, as opposed to the scoring approach used for the Single-Issue Audience models.</h2>
      <br/>
      <br/>
      <p className='rightside'>CinqDI v1.2.03</p>
    
    </div>
    </>
  )
}
