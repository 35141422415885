/* ----------------- 
 * Cinq AI Keywords
 * ----------------- */

export const cinqAIKeywords = [
    {
        audience: 'motivated_to_vote_pro_choice_YES',
        audience_name: 'Motivated to vote pro-choice',
        audience_type: 'Single Issue',
        keywords: "Pro-choice,Abortion rights,women's rights,Abortion supporters,issue voters,reproductive rights,social issues,family planning,politics"
    },
    {
        audience: 'motivated_to_vote_pro_life_YES',
        audience_name: 'Motivated to vote pro-life',
        audience_type: 'Single Issue',
        keywords: "Anti-abortion,Pro-life,Right to life,issue voters,social issues,politics"
    },
    {
        audience: 'Motivated to Vote for Gun Control_YES',
        audience_name: 'Pro-gun control',
        audience_type: 'Single Issue',
        keywords: "Gun control candidates,Common-sense gun laws,Support stricter gun regulations,Vote for gun safety,issue voters,politics,gun regulations"
    },
    {
        audience: 'reasonable_conservative_model_YES',
        audience_name: 'Pragmatic Conservatives',
        audience_type: 'Single Issue',
        keywords: "Moderate conservatives,Conservative without extremism,Reject Jan 6,Middle-ground conservatives,Balanced conservatives,Oppose Jan 6,politics,conservatives"
    },
    {
        audience: 'crime conscious citizens_YES',
        audience_name: 'Crime-Conscious Citizens',
        audience_type: 'Single Issue',
        keywords: "Crime, Safety,Anti-crime,Policing,politics,law enforcement,public safety"
    },
    {
        audience: 'corporate_free_speech_YES',
        audience_name: 'Corporate Free Speech Advocates',
        audience_type: 'Single Issue',
        keywords: "Corporate free speech,Free speech,corportate advocactes,Business and politics,Corporate rights,corporate messaging,politics,corporate communication,public relations"
    },
    {
        audience: 'corporate_spokesperson_allies_YES',
        audience_name: 'Corporate Spokesperson Allies',
        audience_type: 'Single Issue',
        keywords: "Corporate spokesperson,spokesperson,corportate messaging,corportate choice,corportate allies,politics,corporate communication,public relations"
    },
    {
        audience: 'donor_model_YES',
        audience_name: 'Motivated Donors',
        audience_type: 'Single Issue',
        keywords: "Charitable giving,political contributions,Donations,Philanthropic giving,Social impact donations,donors,politics,fundraising"
    },
    {
        audience: 'activist_model_YES',
        audience_name: 'Activists',
        audience_type: 'Single Issue',
        keywords: "Activists,Activist,Activism,Volunteer,Grassroots,campaigns and rallies	politics,political activism,social advocacy"
    },
    {
        audience: 'gas_energy_supporter_YES',
        audience_name: 'Natural Gas Proponents',
        audience_type: 'Single Issue',
        keywords: "natural gas,fossil fuels,energy,energy supporters,natural gas supporters,politics,clean energy,sustainability"
    },
    {
        audience: 'solar_energy_supporter_model_YES',
        audience_name: 'Solar Farm Proponents',
        audience_type: 'Single Issue',
        keywords: "solar farms,Renewable energy,Solar energy advocates,Sustainable energy,Renewable energy supporters,politics,clean energy,renewable resources,low carbon,sustainability,alternative energy"
    },
    {
        audience: 'wind_energy_supporter_model_YES',
        audience_name: 'Off-Shore Wind Proponents',
        audience_type: 'Single Issue',
        keywords: "Offshore wind farms,Wind energy supporters,wind energy,Offshore wind energy,renewable energy,politics,clean energy,renewable resources,low carbon,sustainability,alternative energy"
    },
    {
        audience: 'Role of Government Personas_1 - Liberal Professionals',
        audience_name: 'Liberal Professionals',
        audience_type: 'Cluster',
        keywords: "Affluent progressive,Trust in government,Progressive professionals,Socially conscious,liberal,progressive,voters,politics,elites,left wing"
    },
    {
        audience: 'Role of Government Personas_2 - Working Class Progressives',
        audience_name: 'Working Class Progressives',
        audience_type: 'Cluster',
        keywords: "diverse,left-leaning,working class,progressive,liberal,voters,politics"
    },
    {
        audience: 'Role of Government Personas_3 - Seniors 4 Safety Net',
        audience_name: 'Seniors 4 Safety Net',
        audience_type: 'Cluster',
        keywords: "government spending,Senior,safety,Senior care,medicare,voters,politics"
    },
    {
        audience: 'Role of Government Personas_4 - Diverse & Devout',
        audience_name: 'Diverse & Devout',
        audience_type: 'Cluster',
        keywords: "diverse,moderate,Faith-based,religious,devout,voters,politics,faith,christianity,christian"
    },
    {
        audience: 'Role of Government Personas_5 - Family First Affluent',
        audience_name: 'Family First Affluent',
        audience_type: 'Cluster',
        keywords: "Affluent,Fiscally conservative,Politically disengaged,family first,Suburban,fiscal conservatism"
    },
    {
        audience: 'Role of Government Personas_6 - Skeptical Persuadable Women',
        audience_name: 'Skeptical Persuadable Women',
        audience_type: 'Cluster',
        keywords: "Anti-establishment,Disillusioned,Middle-income,skeptics,Persuadable female voters,women,persuadable,female voters,women voters,politics,voters"
    },
    {
        audience: 'Role of Government Personas_7 - Libertarian Millennial Men',
        audience_name: 'Libertarian Millennial Men',
        audience_type: 'Cluster',
        keywords: "Anti-government,Pro-social reform,Fiscally conservative,Millennial,libertarian,voters,politics"
    },
    {
        audience: 'Role of Government Personas_8 - Establishment GOP Men',
        audience_name: 'Establishment GOP Men',
        audience_type: 'Cluster',
        keywords: "conservative,GOP,Government institution,right leaning,male voters,voters,establishment,politics"
    },
    {
        audience: 'Role of Government Personas_9 - Christian Conservative Women',
        audience_name: 'Christian Conservative Women',
        audience_type: 'Cluster',
        keywords: "religious,conservative,socially conservative,faith-based,Christian,female voters,women voters,voters,politics,christianity,faith"
    },
    {
        audience: 'Role of Government Personas_10 - MAGA Loyalists',
        audience_name: 'MAGA Conservatives',
        audience_type: 'Cluster',
        keywords: "Hyper-partisan,Anti-government MAGA,Trump supporters 2023,MAGA,GOP voters,conservatives,far right,voters,politics,right wing,Trump"
    },
    {
        audience: 'gas_most_reliable_model_output_YES',
        audience_name: 'Natural Gas - Most Reliable',
        audience_type: 'Single Issue',
        keywords: "natural gas,fossil fuels,energy,energy supporters,natural gas supporters,politics,clean energy,sustainability"
    },
    {
        audience: 'solar_most_reliable_YES',
        audience_name: 'Solar - Most Reliable',
        audience_type: 'Single Issue',
        keywords: "solar farms,Renewable energy,Solar energy advocates,Sustainable energy,Renewable energy supporters,politics,clean energy,renewable resources,low carbon,sustainability,alternative energy"
    },
    {
        audience: 'ev_likely_to_consider_YES',
        audience_name: 'EV Supporters',
        audience_type: 'Single Issue',
        keywords: "electric vehicles,Renewable energy,EV buyers,Sustainable energy,electric cars,politics,clean energy,hybrid vehicles,low carbon,sustainability,alternative energy"
    },
    {
        audience: 'wind_energy_oppose_model_YES',
        audience_name: 'Off-Shore Wind Opponents',
        audience_type: 'Single Issue',
        keywords: 'offshore wind farms, wind energy supporters,wind energy, offshore wind energy, renewable energy, politics, clean energy, renewable resources, low carbon, sustainability ,alternative energy'
    },
    {
        audience: 'gas_energy_oppose_model_YES',
        audience_name: 'Natural Gas Opponents',
        audience_type: 'Single Issue',
        keywords: 'natural gas,fossil fuels,energy,energy supporters,natural gas supporters,politics,clean energy,sustainability'
    },
    {
        audience: 'solar_energy_oppose_YES',
        audience_name: 'Solar Farm Opponents',
        audience_type: 'Single Issue',
        keywords: 'solar farms,renewable energy,solar energy advocates,sustainable energy,renewable energy supporters,politics,clean energy,renewable resources,low carbon,sustainability,alternative energy'
    },
    {
        audience: 'likely_donor_YES',
        audience_name: 'Likely to Donate to University',
        audience_type: 'Single Issue',
        keywords: 'charitable giving,donations,philanthropic giving,donors,fundraising,education'
    },
    {
        audience: 'likely_to_pursue_professional_model_YES',
        audience_name: 'Likely to Pursue Professional Training',
        audience_type: 'Single Issue',
        keywords: 'education,professional training,vocational tech,vocational school'
    },
    {
        audience: 'career_changers_model_YES',
        audience_name: 'Career Changers / Job Seekers',
        audience_type: 'Single Issue',
        keywords: 'career changers,jobs,job seekers,employment'
    },
    {
        audience: 'university_donor_YES',
        audience_name: 'Current University Donors',
        audience_type: 'Single Issue',
        keywords: 'charitable giving,donations,philanthropic giving,donors,fundraising,education'
    },
    {
        audience: 'More Motivated to Vote for Gun Rights_YES',
        audience_name: 'Motivated to Vote to Support Gun Rights',
        audience_type: 'Single Issue',
        keywords: 'gun rights,2nd amendment,2A,gun advocates'
    },
    {
        audience: 'dc_influencers_YES',
        audience_name: 'Policy Influencers',
        audience_type: 'Single Issue',
        keywords: 'policy influencers, political stakeholders, policy makers, influencers, stakeholders'
    },
    {
        audience: 'Student Loan Forgiveness_SUPPORT',
        audience_name: 'Support Student Loan Forgiveness',
        audience_type: '',
        keywords: 'education, student loans, loan forgiveness'
    },
    {
        audience: 'Student Loan Forgiveness_OPPOSE',
        audience_name: 'Oppose Student Loan Forgiveness',
        audience_type: '',
        keywords: 'education, student loans, loan forgiveness'
    },
    {
        audience: 'Arming Teachers_SUPPORT',
        audience_name: 'Support Arming Teachers',
        audience_type: 'Single Issue',
        keywords: 'armed teachers, arming teachers, teachers with guns, carrying in schools, armed school staff, education'
    },
    {
        audience: 'Arming Teachers_OPPOSE',
        audience_name: 'Oppose Arming Teachers',
        audience_type: 'Single Issue',
        keywords: 'armed teachers, arming teachers, teachers with guns, carrying in schools, armed school staff, education'
    },
    {
        audience: 'Pro Parents in the Classroom_YES',
        audience_name: 'Pro Parents in Classroom',
        audience_type: 'Single Issue',
        keywords: 'parental rights, education rights, parents in classroom, education'
    },
    {
        audience: 'Book Banning_SUPPORT',
        audience_name: 'Support Book banning',
        audience_type: 'Single Issue',
        keywords: 'book banning, education'
    },
    {
        audience: 'Book Banning_OPPOSE',
        audience_name: 'Oppose Book banning',
        audience_type: 'Single Issue',
        keywords: 'book banning, education'
    },
    {
        audience: 'Financially Stable_YES',
        audience_name: 'Financially Stable',
        audience_type: 'Single Issue',
        keywords: 'finance, personal finance, income, net worth, spending'
    },
    {
        audience: 'Frequent Travelers_YES',
        audience_name: 'Frequent Travelers',
        audience_type: 'Single Issue',
        keywords: 'travel'
    },
    {
        audience: 'interest_politics_YES',
        audience_name: 'Political Buffs',
        audience_type: 'Single Issue',
        keywords: 'new readers, political news, news, media'
    },
    {
        audience: 'youtube_user_YES',
        audience_name: 'YouTubers',
        audience_type: 'Single Issue',
        keywords: 'youtube, media, streamer'
    },
    {
        audience: 'interest_entertainment_YES',
        audience_name: 'Enterntainment Enthusiasts',
        audience_type: 'Single Issue',
        keywords: 'entertainment, news, readers, media'
    },
    {
        audience: 'cable_viewer_YES',
        audience_name: 'Cable Connectors',
        audience_type: 'Single Issue',
        keywords: 'cable, news, television, TV, media'
    },
    {
        audience: 'national_news_YES',
        audience_name: 'National News Enthusiasts',
        audience_type: 'Single Issue',
        keywords: 'new readers, national, news, media'
    },
    {
        audience: 'y_contraception_act_support_YES',
        audience_name: 'Contraception Act Supporters',
        audience_type: 'Single Issue',
        keywords: "Pro-choice,Abortion rights,women's rights,Abortion supporters,issue voters,reproductive rights,social issues,family planning,politics,contraception,birth control"
    },
    {
        audience: 'Education Personas_Career Climbers & Caretakers',
        audience_name: 'Career Climbers & Caretakers - Education',
        audience_type: 'Cluster',
        keywords: "education,career advancement,conservative,caretakers,parents,higher ed"
    },
    {
        audience: 'Education Personas_Pro Public School Parents',
        audience_name: 'Pro Public-School Parents - Education',
        audience_type: 'Cluster',
        keywords: "education,college educated,liberal,public schools,parents,public education,higher ed"
    },
    {
        audience: 'Education Personas_Heartland Families',
        audience_name: 'Heartland Families - Education',
        audience_type: 'Cluster',
        keywords: 'education,parents,associates degree,higher ed'
    },
    {
        audience: 'Education Personas_Faith Family & 529',
        audience_name: 'Faith, Family & 529 - Education',
        audience_type: 'Cluster',
        keywords: 'education,college education,religious,conservative,529,parents,higher ed'
    },
    {
        audience: 'Education Personas_Diverse Skill Builders',
        audience_name: 'Diverse Skill Builders - Education',
        audience_type: 'Cluster',
        keywords: 'diversity,education,career advancement,donors,trade school,higher ed'
    },
    {
        audience: 'Education Personas_Charter School Crusaders',
        audience_name: 'Charter School Crusaders - Education',
        audience_type: 'Cluster',
        keywords: 'education,charter schools,school choice,donors,religious,higher ed'
    },
    {
        audience: 'Education Personas_Affluent Alumni',
        audience_name: 'Affluent Alumni - Education',
        audience_type: 'Cluster',
        keywords: 'education,donors,alumni,masters degree,higher ed'
    },
    {
        audience: 'Education Personas_Young Pro Education Progressives',
        audience_name: 'Young Pro Education Progressives - Education',
        audience_type: 'Cluster',
        keywords: 'education,public schools,liberal,loan forgiveness,student debt,higher ed'
    },
    {
        audience: 'Education Personas_Pro Public Education Seniors',
        audience_name: 'Pro Public Education Seniors - Education',
        audience_type: 'Cluster',
        keywords: 'education,public schools,liberal,boomers,seniors,higher ed'
    },
    {
        audience: 'Education Personas_Disconnected Diverse & Day-to-Day',
        audience_name: 'Disconnected, Diverse & Day-to-Day - Education',
        audience_type: 'Cluster',
        keywords: 'education,working class,diverse,higher ed'
    },
    {
        audience: 'voter_enthusiasm_YES',
        audience_name: 'Voter Enthusiasm',
        audience_type: 'Single Issue',
        keywords: 'voter engagement,voting rights,voting reform,civic engagement,voter rights,grassroots politics,election involvement,voter turnout,campaign volunteerism,voter registration'
    },
    {
        audience: 'definitely_probably_voting_kennedy_YES',
        audience_name: 'Definitely or Probably Vote for Kennedy',
        audience_type: 'Single Issue',
        keywords: 'voters,politics,Kennedy,RFK,Jr, Robert F. Kennedy,independent,libertarian-minded voters,non-establishment,vaccine safety,medical and public health,government accountability,environmental advocacy'
    },
    {
        audience: 'definitely_probably_voting_trump_YES',
        audience_name: 'Definitely or Probably Vote for Trump',
        audience_type: 'Single Issue',
        keywords: 'hyper-partisan,anti-government,MAGA,Trump supporters,GOP voters,conservatives,far right,voters,politics,right wing,Trump,JD Vance,tax reform,immigration'
    },
    {
        audience: 'definitely_voting_harris_YES',
        audience_name: 'Definitely Vote for Harris',
        audience_type: 'Single Issue',
        keywords: 'Kamala Harris,KHive,Harris supporters,Tim Walz,democratic voters,voters,politics,left wing,moderates,reproductive rights,women\'s rights,progressive policies,race and social justice'
    },
    {
        audience: 'y_lgbt_hiring_YES',
        audience_name: 'LGBTQ Hiring Discrimination Support',
        audience_type: 'Single Issue',
        keywords: 'gay rights,LGBTQ,diversity,equity,and inclusion,DEI,workplace discrimination,discrimination law,hiring discrimination,workplace equality,discrimination,gay,lesbian,queer,bisexual,transgender,LGBTQ advocacy'
    },
    {
        audience: 'y_lgbt_candidate_YES',
        audience_name: 'LGBTQ Candidate Support',
        audience_type: 'Single Issue',
        keywords: 'support lgbtq candidates,lgbtq candidates,lgbtq political advocacy,lgbtq-friendly political organizations,lgbtq political campaigns,political leadership,political issues,lgbtq politicians,diversity and inclusion,gay rights,lgbtq,gay,lesbian,queer,bisexual,transgender'
    },
    {
        audience: 'y_lgbt_ally_YES',
        audience_name: 'LGBTQ/Ally',
        audience_type: 'Single Issue',
        keywords: 'gay rights,LGBTQ,allies,diversity and inclusion,human rights,discrimination,gay,lesbian,queer,bisexual,transgender'
    },
    {
        audience: 'Support Universal Background Checks_YES',
        audience_name: 'Support Universal Background Checks',
        audience_type: 'Single Issue',
        keywords: 'Universal background checks,gun control,guns,gun safety,gun policy,gun reform,assault rifle ban,assault weapon ban,mass shooting,NRA,pro-gun,anti-gun'
    },
]